import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, NgZone, SecurityContext } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, PreloadAllModules, provideRouter, Router, RouterModule } from '@angular/router';

import {
  AnalyticsService,
  BaseConfigService,
  NavigationBarService,
  WebCommonsModule,
  WebSocketConfig
} from '@apiax/web-commons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { KeycloakService } from 'keycloak-angular';
import { MarkdownModule } from 'ngx-markdown';
import {
  LocalStorageService,
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
  withSessionStorage
} from 'ngx-webstorage';
import { debounceTime, filter } from 'rxjs/operators';

import { getConfig } from 'shared/app';
import { ActivityAllowedGuard, AuthGuard } from 'shared/app/guards';
import { LoadingGuard } from 'shared/app/guards/loading.guard';
import { WINDOW } from 'shared/app/utils/window.token';
import { CoreModule } from 'shared/core';
import { SharedModule } from 'shared/shared.module';
import { ApiModule, Configuration } from '../generated';
import version from '../version';

import { AppComponent } from './app.component';
import { DataResolver } from './app.resolver';
import { ROUTES } from './app.routes';
import { UnauthorizedInterceptor } from './domain/interceptors/unauthorized.interceptor';
import { BalState } from './domain/stores/chat-bal/chat-bal.state';
import { ChatPreviewState } from './domain/stores/chat-preview/chat-preview.state';
import { ChatState } from './domain/stores/chat/chat.state';
import { ChatbotConfigurationsState } from './domain/stores/chatbotconfigurations/chatbot-configurations.state';
import { ErrorsState } from './domain/stores/errors/chat-errors.state';
import { SettingsState } from './domain/stores/settings/settings.state';
import { VerificationsState } from './domain/stores/verifications/verifications.state';
import { VerificationsPanelModule } from './presentation/verifications-panel/verifications-panel.module';

const GUARDS_PROVIDERS = [AuthGuard, LoadingGuard, ActivityAllowedGuard];

// Application wide providers
const APP_PROVIDERS = [DataResolver, ...GUARDS_PROVIDERS];

export const ADVANCE_MODULES = [
  CoreModule.forRoot([]),
  RouterModule.forRoot(ROUTES, {
    useHash: getConfig('useHash'),
    preloadingStrategy: PreloadAllModules,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })
];

export function buildConfigChatJavaAPI(): Configuration {
  const config = new Configuration();
  config.basePath = getConfig('apiJavaUrl');
  return config;
}

export function kcInitializer(
  keycloak: KeycloakService,
  ngZone: NgZone,
  localStorage: LocalStorageService
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): () => Promise<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (): Promise<any> => {
    localStorage.clear('kc-logout');
    localStorage.observe('kc-logout').subscribe(() => keycloak.logout());
    return ngZone.runOutsideAngular(() => keycloak.init(getConfig('keycloakOptions')));
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  const rightNow = new Date();
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cacheBuster=' + rightNow.getTime());
}

export function windowFactory() {
  return window;
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ...ADVANCE_MODULES,
    ApiModule.forRoot(buildConfigChatJavaAPI),
    SharedModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    VerificationsPanelModule,
    NgxsModule.forRoot([
      SettingsState,
      ChatState,
      ChatPreviewState,
      ChatbotConfigurationsState,
      VerificationsState,
      BalState,
      ErrorsState
    ], {
      compatibility: {
        strictContentSecurityPolicy: true
      }
    }),
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE
    }),
    WebCommonsModule
  ],
  providers: [
    provideRouter(ROUTES),
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withNgxWebstorageConfig({
        prefix: 'apx',
        separator: '.'
      }),
      withLocalStorage(),
      withSessionStorage()
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: kcInitializer,
      multi: true,
      deps: [KeycloakService, NgZone, LocalStorageService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: WINDOW, useFactory: windowFactory },
    NavigationBarService,
    { provide: WebSocketConfig, useValue: { baseUrl: getConfig('apiJavaUrl') } },
    ...APP_PROVIDERS
  ]
})
export class AppModule {
  constructor(
    private router: Router,
    baseConfigService: BaseConfigService,
    analytics: AnalyticsService
  ) {
    baseConfigService.load();
    baseConfigService.set('apm.serviceName', 'chat-web');
    baseConfigService.set('apm.serviceVersion', version);
    analytics.initialize();

    if (baseConfigService.getAnalyticsConfig().active && baseConfigService.get('posthog.capture_pageview')) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          debounceTime(200)
        )
        .subscribe(() => {
          // @ts-ignore
          window.posthog?.capture('$pageview');
        });
    }
  }
}
